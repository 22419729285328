<template>
    <st-section :header="title">
        <template #body>
            <form class="form" novalidate="novalidate" id="st_ac_tax_validator_form">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label>
                                {{ fields.territorial_administrative_unit_id.label }} *
                            </label>
                            <div>
                                <st-autocomplete
                                    v-model="model[fields.territorial_administrative_unit_id.name]"
                                    :ref="fields.territorial_administrative_unit_id.name"
                                    :name="fields.territorial_administrative_unit_id.name"
                                    :options="uatsOptions"
                                    :disabled="viewMode"
                                    :fields="fields"
                                    :model="model"
                                    :formValidation="fv"
                                    @change="updateSelectedUat"
                                >
                                </st-autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label>
                                {{ fields.village_id.label }} *
                            </label>
                            <div>
                                <st-autocomplete
                                    v-model="model[fields.village_id.name]"
                                    :options="fields.village_id.options"
                                    :ref="fields.village_id.name"
                                    :name="fields.village_id.name"
                                    :disabled="!fields.village_id.options.length || viewMode"
                                    :model="model"
                                    :fields="fields"
                                    :formValidation="fv"
                                    @change="updateSelectedVillage"
                                >
                                </st-autocomplete>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label>
                                {{ fields.street.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.street.name"
                                    :name="fields.street.name"
                                    v-model="model[fields.street.name]"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label>
                                {{ fields.street_number.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.street_number.name"
                                    :name="fields.street_number.name"
                                    v-model="model[fields.street_number.name]"
                                    :disabled="viewMode"
                                    allow-only-alphanumeric
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label>
                                {{ fields.construction_scope.label }}: *
                            </label>
                            <div>
                                <b-form-radio-group
                                    size="lg"
                                    v-model="model[fields.construction_scope.name]"
                                    :options="fields.construction_scope.options"
                                    :ref="fields.construction_scope.name"
                                    :field="fields.construction_scope"
                                    :formValidation="fv"
                                    :disabled="viewMode"
                                    @change="updateConstructionScope"
                                    stacked
                                ></b-form-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>
                                {{ fields.construction_type.label }}: *
                            </label>
                            <div>
                                <b-form-radio-group
                                    size="lg"
                                    v-model="model[fields.construction_type.name]"
                                    :options="fields.construction_type.options"
                                    :ref="fields.construction_type.name"
                                    :field="fields.construction_type"
                                    :formValidation="fv"
                                    :disabled="viewMode || constructionTypeChoiceDisabled"
                                    @change="updateConstructionType"
                                    stacked
                                ></b-form-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label>
                                {{ fields.surface.label }} *
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.surface.name"
                                    :name="fields.surface.name"
                                    v-model="model[fields.surface.name]"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <label>
                                {{ fields.construction_value.label }}: *
                                <span v-if="minTaxValueDisplay"> (minim {{ minTaxValueDisplay }})</span>
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.construction_value.name"
                                    :name="fields.construction_value.name"
                                    v-model="model[fields.construction_value.name]"
                                    :disabled="!taxCanBeCompleted || viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12">
                        <div class="form-group">
                            <label>
                                {{ fields.construction_purpose.label }}: *
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.construction_purpose.name"
                                    :name="fields.construction_purpose.name"
                                    v-model="model[fields.construction_purpose.name]"
                                    :disabled="viewMode"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </template>
        <template #buttons>

        </template>
    </st-section>
</template>

<script>
import {
    AcTaxValidatorModel,
    SCOPE_BUILDING,
    SCOPE_OTHER,
    TYPE_CONCRETE,
    TYPE_WOOD,
    TYPE_OTHER,
} from "@/modules/applications/models/ac-tax-validator.model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import { mapGetters } from "vuex";

const DEFAULT_COEFFICIENT = 1;
const TAX_CATEGORY_DEFAULTS = {
    TAX_A: 1250,
    TAX_B: 750,
    TAX_C: 500,
    TAX_OTHER: 1750,
}

const { fields } = AcTaxValidatorModel;

const formSchema = new FormSchema([
    fields.id,
    fields.county,
    fields.county_id,
    fields.territorial_administrative_unit,
    fields.territorial_administrative_unit_id,
    fields.village,
    fields.village_id,
    fields.street,
    fields.street_number,
    fields.construction_scope,
    fields.construction_scope_text,
    fields.construction_type,
    fields.construction_type_text,
    fields.surface,
    fields.construction_value,
    fields.construction_purpose,
]);

export default {
    name: "AcTaxValidator",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        coeff: {
            type: Array,
            default: () => [],
        },
        taxCategories: {
            type: Object,
            default: () => TAX_CATEGORY_DEFAULTS,
        }
    },
    data() {
        return {
            fields,
            model: null,
            rules: formSchema.rules(),
            fv: null,
            minTaxValue: null,
            currentScope: null,
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
            record: "applications/form/record",
            loading: "shared/loading",
        }),
        availableUatIds() {
            const currentState = this.moduleName ?? 'applications';
            return this.$store.state[currentState]?.form?.availableUatIds;
        },
        hasUatSelected() {
            return !!this.model[this.fields.territorial_administrative_unit_id.name];
        },
        uatsOptions() {
            return fields.territorial_administrative_unit_id.options
                .filter(option => this.availableUatIds.find(id => option.value === id));
        },
        watchedValues() {
            return {
                territorial_administrative_unit_id: this.model?.territorial_administrative_unit_id,
                village_id: this.model?.village_id,
                construction_scope: this.model?.construction_scope,
                construction_type: this.model?.construction_type,
                surface: this.model?.surface,
                construction_value: this.model?.construction_value,
            }
        },
        constructionTypeChoiceDisabled() {
            return this.model?.construction_scope == SCOPE_OTHER;
        },
        taxCanBeCompleted() {
            return this.model?.village_id && this.model?.surface;
        },
        minTaxValueOk() {
            return !this.minTaxValue && this.minTaxValue < this.model.construction_value;
        },
        minTaxValueDisplay() {
            if(!this.minTaxValue || isNaN(parseFloat(this.minTaxValue))) {
                return false;
            }
            return (this.minTaxValue ?? 0).toFixed(2);
        },
    },
    methods: {
        updateSelectedUat() {
            const uats = this.fields.territorial_administrative_unit_id.options;
            this.model.territorial_administrative_unit = uats.find( v => v.value === this.model.territorial_administrative_unit_id)?.text;
            if(this.fields.construction_value) {
                this.model[this.fields.construction_value.name] = null;
            }
        },
        updateSelectedVillage() {
            this.model.village = this.fields.village_id?.options?.find( v => v.value === this.model.village_id)?.text;
        },
        updateConstructionScope(cScope) {
            this.currentScope = cScope;
            const options = this.fields.construction_scope.options;
            this.model.construction_scope_text = options.find(v => v.value === this.model.construction_scope)?.text;
            this.updateConstructionType();
        },
        updateConstructionType() {
            const options = this.fields.construction_type.options;
            if(this.model.construction_scope == SCOPE_OTHER) {
                this.model.construction_type_text = '';
            } else {
                this.model.construction_type_text = options.find(v => v.value === this.model.construction_type)?.text;
            }
            this.revalidateMFields();
        },
        revalidateMFields() {
            this.fv?.revalidateField(fields.construction_value.name);
            this.fv?.revalidateField(fields.surface.name);
            this.fv?.revalidateField(fields.construction_purpose.name);
        },
        recalculateMinTax(values) {
            const coefficient = this.coeff?.find(v => v.id == values.village_id)?.coefficient ?? DEFAULT_COEFFICIENT;

            switch(values.construction_scope) {
                case SCOPE_BUILDING:
                    let taxValueByType;
                    switch (values.construction_type) {
                        case TYPE_CONCRETE:
                            taxValueByType = this.taxCategories.TAX_A;
                            break;
                        case TYPE_WOOD:
                            taxValueByType = this.taxCategories.TAX_B;
                            break;
                        case TYPE_OTHER:
                            taxValueByType = this.taxCategories.TAX_C;
                            break;
                    }
                    this.minTaxValue = taxValueByType * coefficient * parseFloat(this.model.surface);
                    this.minTaxValue = parseFloat(Number(this.minTaxValue).toFixed(2));
                    break;
                case SCOPE_OTHER:
                    this.minTaxValue = this.taxCategories.TAX_OTHER;
                    break;
            }

            switch(values.construction_scope) {
                case SCOPE_BUILDING:
                    if(this.isNumeric(values.construction_value ?? 0)) {
                        if(this.currentScope && this.currentScope != SCOPE_BUILDING) {
                            // TODO: cleanup land_value from the base form
                            // this.$emit("change", 0, "land_value");
                        }
                        this.$emit("change", parseFloat(values.construction_value ?? 0), "construction_value");
                    }
                    break;
                case SCOPE_OTHER:
                    if(this.isNumeric(values.construction_value ?? 0)) {
                        if(this.currentScope && this.currentScope != SCOPE_OTHER) {
                            // TODO: cleanup construction_value from the base form
                            // this.$emit("change", 0, "construction_value");
                        }
                        this.$emit("change", parseFloat(values.construction_value ?? 0), "land_value");
                    }
                    break;
            }
        },
        isNumeric(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }
    },
    watch: {
        model: {
            deep: true,
            immediate: false,
            handler(newValues) {
                this.$emit("change", newValues, "ac_tax_validator");
            }
        },
        watchedValues: {
            deep: false,
            immediate: false,
            handler(newValues) {
                this.recalculateMinTax(newValues);
            },
        },
    },
    async created() {
        this.fields.county_id.options = this.fields.county_id.options.filter(
            (el) => {
                return el.value === this.defaultCountyId;
            }
        );

        if (this.record?.form?.ac_tax_validator) {
            this.model = formSchema.initialValues(this.record.form.ac_tax_validator);
        } else {
            const cScope = formSchema.fields.find(v => v.name == 'construction_scope');
            const cType = formSchema.fields.find(v => v.name == 'construction_type');
            const record = {
                construction_scope: cScope.options[0].value,
                construction_type: cType.options[0].value,
                county_id: this.defaultCountyId,
            };

            this.model = formSchema.initialValues(record);
        }
    },
    mounted() {
        this.fv = createFormValidation("st_ac_tax_validator_form", this.rules);
        this.fv.addField(fields.construction_value.name, {
            validators: {
                callback: {
                    message: this.$t('AC_TAX_VALIDATOR.ERRORS.CONSTRUCTION_VALUE_TOO_SMALL'),
                    callback: (input)=> {
                        if(!this.minTaxValue || !input.value) return true;
                        if(input.value?.length > 1 && parseInt(input.value) > 1 && input.value.startsWith('0')) return false;
                        return parseFloat(Number(input.value).toFixed(2)) >= this.minTaxValue;
                    },
                },
            }
        });
        this.fv.addField(fields.surface.name, {
            validators: {
                callback: {
                    message: this.$t('AC_TAX_VALIDATOR.ERRORS.SURFACE_WRONG_FORMAT'),
                    callback: (input)=> {
                        if(parseInt(input.value) <= 0) return false;
                        if(input.value?.length > 1 && parseInt(input.value) > 1 && input.value.startsWith('0')) return false;
                        return true;
                    },
                },
            }
        });

        // county meta
        const counties = this.fields.county_id.options;
        const selectedCounty = counties.find( county => county.value === this.model.county_id);
        this.model.county = selectedCounty?.text;

        this.updateConstructionScope();

    },
};
</script>

<style scoped>
</style>
